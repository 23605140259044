import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';
import { Lookup } from '../../../shared/models';
import { FormControl, FormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

@Component({
	selector: 'operator-selection-lookup-form',
	templateUrl: './operator-selection-lookup-form.component.html'
})
export class OperatorSelectionLookupFormComponent implements OnInit, OnChanges, OnDestroy {
	@Input() lookup: Lookup;
	@Output() changed = new EventEmitter<Lookup>();
	form: FormGroup;
  private formValueChangesSubscription: Subscription;

	ngOnChanges(changes: SimpleChanges): void {
		if (!this.form) {
			return;
		}

		if (changes.lookup && changes.lookup.currentValue) {
			if (this.form.get('search').value !== this.lookup.searchTerm) {
				this.form
					.get('search')
					.setValue(this.lookup.searchTerm, { emitEvent: false });
			}
		}
	}

	ngOnInit(): void {
		this.form = new FormGroup({
			search: new FormControl(this.lookup?.searchTerm)
		});


    this.formValueChangesSubscription = this.form.valueChanges.subscribe((data) => {
      const lookup: Lookup = _.cloneDeep(this.lookup);
      let changed = false;

      if (data.search !== lookup.searchTerm) {
        lookup.searchTerm = data.search;
        changed = true;
      }

      if (changed) {
        this.changed.emit(lookup);
      }
    });
	}

	clear(): void {
		this.changed.emit(new Lookup());
	}

  ngOnDestroy(): void {
    if (this.formValueChangesSubscription) {
      this.formValueChangesSubscription.unsubscribe();
    }
  }
}
