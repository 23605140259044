import { Operator } from '../../../models/operator';
import * as _ from 'lodash';

export class OperatorMapperService {
  static parseOperatorInternal(item: any): Operator {
    const o = new Operator();

    o.id = item.operatorUuid;
    o.name = item.name;
    o.legalName = item.legalName;
    o.eulaAccepted = item.eulaAccepted;
    o.operatorAgreement = item.operatorAgreement;
    if (item.prospectus) {
      o.prospectusApproved = item.prospectus.approved;
    }

    return o;
  }
}
