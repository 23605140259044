<!-- START HEADER -->
<div class="header p-r-0 p-l-0">
  <div class="container-fluid">
    <div class="page-content-wrapper">
      <div class="header-md-height">
        <!-- LEFT SIDE -->
        <div class="pull-left">
          <h3>{{title}}</h3>
        </div>



        <!-- START User Info-->

        <div class="pull-right toggle-sidebar-btn"
             style="z-index: 2; margin-top: 10px;">
          <!-- START ACTION BAR -->
          <a (click)="toggleSidebar()"
             class="m-t-10 btn-link toggle-sidebar padding-5"
             href="javascript:void(0)">
            <span class="pg pg-menu"></span>
          </a>
          <!-- END ACTION BAR -->
        </div>


        <div class="pull-right"
             style="z-index: 2">
          <div class="dropdown pull-right"
               dropdown>
            <button class="profile-dropdown-toggle"
                    type="button"
                    dropdownToggle
                    id="profileDropdown">
              <svg xmlns="http://www.w3.org/2000/svg"
                   width="40"
                   height="40"
                   viewBox="0 0 40 40">
                <path d="M20,20.87a6,6,0,0,1-6.09-5.82q0-.13,0-.27V12.17a6.09,6.09,0,0,1,12.18,0v2.61a6,6,0,0,1-5.82,6.09Zm0-13a4.21,4.21,0,0,0-4.35,4.06q0,.14,0,.29v2.61a4.35,4.35,0,0,0,8.7,0V12.17a4.21,4.21,0,0,0-4.06-4.35ZM31.3,33H29.57V28.7c0-3.07-6.21-5.22-9.57-5.22s-9.57,2.15-9.57,5.22V33H8.7V28.7c0-4.57,7.36-7,11.3-7s11.3,2.39,11.3,7V33ZM20,40A20,20,0,1,1,40,20,20,20,0,0,1,20,40ZM20,1.74A18.26,18.26,0,1,0,38.26,20h0A18.28,18.28,0,0,0,20,1.74Z"
                      fill="#d7d8de" /></svg>
              <div class="bold m-t-5">
                {{ userAccount?.operator?.name }}
              </div>
            </button>
            <ul class="dropdown-menu dropdown-menu-right p-b-0 p-t-0"
                *dropdownMenu
                role="menu">
                <ng-container *ngIf="isAngelModAccount">
                  <li role="menuitem">
                    <div class="radio radio-primary account-radio-group col">
                      <div class="row account-radio">
                        <input
                          id="account-angel"
                          type="radio"
                          name="account"
                          [checked]="true"
                        />
                        <label class="account-label text-danger" [for]="account-angel">{{userAccount?.operator?.name}}</label>
                      </div>
                    </div>
                  </li>
                  <li class="divider dropdown-divider m-b-0"></li>
                </ng-container>
                <ng-container *ngIf="isAngelModAccount || user.accounts.length > 1">
                  <li role="menuitem">
                    <div class="radio radio-primary account-radio-group col">
                      <ng-container *ngFor="let account of user.accounts; index as i">
                        <div class="row account-radio">
                          <input
                            [id]="'account-' + i"
                            type="radio"
                            name="account"
                            (change)="accountChange.emit(account)"
                            [checked]="account?.operator?.id === userAccount.operator.id"
                          />
                          <label class="account-label" [for]="'account-' + i">{{account.operator.name}}</label>
                        </div>
                      </ng-container>
                    </div>
                  </li>
                  <li class="divider dropdown-divider m-b-0"></li>
                </ng-container>
                <ng-container *ngIf="viewPermissions.actOnBehalfOfAnyOperator">
                  <li role="menuitem">
                    <a
                      class="clearfix dropdown-item text-danger"
                      tooltip="Act on Behalf Of Any Operator"
                      placement="left"
                      (click)="showOperatorSelectionDialog.emit()"
                    >
                      <h6 class="pull-left text-danger">Select Operator</h6>
                      <span class="pull-right"
                            style="margin-top: 13px">
                          <i class="fas fa-hand-pointer"></i>
                      </span>
                    </a>
                  </li>
                  <li class="divider dropdown-divider m-b-0"></li>
                </ng-container>
              <li role="menuitem">
                <a
                  class="clearfix dropdown-item"
                  href="javascript:void(0)"
                  routerLink="/profile"
                  (click)="profileClicked.emit()"
                >
                  <h6 class="pull-left">Profile</h6>
                  <span class="pull-right"
                        style="margin-top: 13px">
                    <i class="fas fa-user"></i>
                  </span>
                </a>
              </li>
              <li class="divider dropdown-divider m-b-0"></li>
              <li role="menuitem">
                <a
                  class="clearfix dropdown-item"
                  href="javascript:void(0)"
                  routerLink="/pricing"
                  (click)="pricingClicked.emit()"
                >
                  <h6 class="pull-left">Pricing</h6>
                  <span class="pull-right"
                        style="margin-top: 13px">
                    <i class="fas fa-coins"></i>
                  </span>
                </a>
              </li>
              <li class="divider dropdown-divider m-b-0"></li>
              <li role="menuitem" class="bg-master-lighter">
                <a class="clearfix dropdown-item"
                   (click)="signOut()">
                  <h6 class="pull-left">Logout</h6>
                  <span class="pull-right"
                        style="margin-top: 13px">
                    <i class="fal fa-power-off"></i>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- END HEADER -->
