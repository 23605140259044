export class Operator {
  id: string;
  name: string;
  legalName: string;
  eulaAccepted: boolean;
  prospectusApproved: boolean;
  operatorAgreement: boolean;

  get legalNameAndName(): string {
    return this.legalName
      && this.name
      && this.legalName == this.name
        ? this.legalName
        : `${this.legalName} (${this.name})`;
  }
}