<form
  autocomplete="off"
  [formGroup]="form"
  class="form-horizontal form-horizontal-condensed"
  novalidate
>
  <div class="flex">
    <div class="m-r-15">
      <div class="form-group">
        <label class="overflow-ellipsis">Search</label>
        <input
          class="form-control"
          id="search"
          type="text"
          formControlName="search"
        />
      </div>
    </div>

    <div class="m-r-15">
      <div class="btn-toolbar flex-wrap m-t-30" role="toolbar">
        <div class="btn-group sm-m-t-10">
          <button id="filterClear" type="button" class="btn btn-default" (click)="clear()">
            <i class="fa fa-times"></i> Clear
          </button>
        </div>
      </div>
    </div>
  </div>
</form>
