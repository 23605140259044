import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import * as actions from '../actions/user';
import * as dataContracts from '../services/user/contracts/user';

import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { IUserService, USER_SERVICE_TOKEN } from '../services/user/contracts/user';
import {
  ANALYTICS_SERVICE_TOKEN,
  IAnalyticsService,
  LOCAL_STORAGE_SERVICE_TOKEN,
  ILocalStorage
} from '../../shared/services';
import * as models from '../models';
import { IOperatorService, OPERATOR_SERVICE_TOKEN } from '../services';
import * as _ from 'lodash'

@Injectable()
export class UserEffects {

  loadUser$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType<actions.LoadAction>(actions.LOAD),
      switchMap(() => {
        const selectedAngelOperatorUuid = this.storageService.getItem('selectedAngelOperatorUuid');
        if (selectedAngelOperatorUuid) {
          return this.operatorService.getOperator({
            operatorUuid: selectedAngelOperatorUuid
          }).pipe(map(response => response.item));
        }
        return of(null);
      }),
      map(operator => {
        if (operator) {
          const fullAccessOperator: models.Operator  = _.cloneDeep(operator);
          fullAccessOperator.eulaAccepted = true;
          fullAccessOperator.operatorAgreement = true;
          fullAccessOperator.prospectusApproved = true;
          return fullAccessOperator;
        }
        return null;
      }),
      switchMap((angelOperator: models.Operator) => {
        const request = new dataContracts.GetUserRequest();
        return this.userService.getUser(request)
          .pipe(
            map(svcResp => {
              const user = svcResp.entity;

              let angelAccount: models.UserAccount = null;
              if (user.viewPermissions.actOnBehalfOfAnyOperator && angelOperator) {
                angelAccount = new models.UserAccount();
                angelAccount.operator = angelOperator;
                angelAccount.superUser = true;
                angelAccount.eulaAccepted = true;
              }

              const selectedAccountId = this.storageService.getItem('selectedAccountId');

              const account = user?.accounts?.find(
                (account) => account.operator?.id === selectedAccountId
              ) || user?.accounts?.[0];

              if (account) {
                this.analyticsService.logUser({
                  ...this.getUserProperties(svcResp.entity, account),
                });
              }

              return new actions.LoadSuccessAction({ user, account: angelAccount || account });
            })
          );
      })
    ));

  signOut$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType<actions.SignOutAction>(actions.SIGN_OUT),
      switchMap(() => {
        return this.userService.signOut(new dataContracts.SignOutRequest())
          .pipe(
            map(svcResp => {
              location.reload();
              return { type: 'NO_ACTION' };
            })
          );
      })
    ));

    logUserActivity$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType<actions.LogUserActivity>(actions.LOG_USER_ACTIVITY),
      map(action => action.payload),
      switchMap((payload) => {
        return this.userService.logUserActivity(payload)
          .pipe(
            map(svcResp => {
              return { type: 'NO_ACTION' };
            })
          );
      })
    ));


  getUserProperties(user: models.User, account: models.UserAccount): any {
    const properties = {} as any;
    properties.UserID = user.userId;
    properties.OperatorID = account.operator.id;
    properties.OrganizationID = account.operator.id;
    properties.User = `${user.firstName} ${user.lastName}`;
    properties.Operator = account.operator.legalName;
    return properties;
  }

  constructor(private actions$: Actions,
              @Inject(USER_SERVICE_TOKEN) private userService: IUserService,
              @Inject(OPERATOR_SERVICE_TOKEN) private operatorService: IOperatorService,
              @Inject(LOCAL_STORAGE_SERVICE_TOKEN) private storageService: ILocalStorage,
              @Inject(ANALYTICS_SERVICE_TOKEN) private analyticsService: IAnalyticsService) {
  }
}
