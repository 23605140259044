import * as models from '../models';
import * as sharedModels from '../../shared/models';
import * as actions from '../actions/operator-collection';

import * as _ from 'lodash';

export interface State {
  isLoaded: boolean;
  isLoading: boolean;
  items: Array<models.Operator>;
  lookup: sharedModels.Lookup;
}

const initialState: State = {
  isLoaded: false,
  isLoading: false,
  items: [],
  lookup: new sharedModels.Lookup(),
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.SET_LOOKUP: {
      return {
        ...state,
        lookup: _.cloneDeep(action.payload),
      }
    }

    case actions.LOAD: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case actions.LOAD_SUCCESS: {
      const items = _.cloneDeep(action.payload.items);
      const lookup = _.cloneDeep(state.lookup);
      lookup.total = action.payload.total;

      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        lookup,
        items,
      };
    }

    case actions.LOAD_FAIL: {
      const lookup = _.cloneDeep(state.lookup);
      return {
        ...initialState,
        isLoaded: true,
        lookup,
      };
    }

    default: {
      return state;
    }
  }
}

export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getItems = (state: State) => state.items;
export const getLookup = (state: State) => state.lookup;
