import { Action } from '@ngrx/store';
import * as models from '../models';
import * as sharedModels from '../../shared/models';

export const LOAD = '[RM Operator Collection] Load';
export const LOAD_SUCCESS = '[RM Operator Collection] Load Success';
export const LOAD_FAIL = '[RM Operator Collection] Load Fail';
export const SET_LOOKUP = '[RM Operator Collection] Set Lookup';

export class LoadAction implements Action {
  readonly type = LOAD;
}

export class LoadSuccessPayload {
  constructor(
    public items: Array<models.Operator>, 
    public total: number
  ) {}
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {}
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export class SetLookupAction implements Action {
  readonly type = SET_LOOKUP;

  constructor(public payload: sharedModels.Lookup) {}
}

export type Actions =
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction
  | SetLookupAction;
