import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';

import * as actions from '../actions/operator-collection';
import * as contacts from '../services/operator/contracts/operator';
import * as reducers from '../reducers';

import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import * as sharedTypes from '../../shared/types';

@Injectable()
export class OperatorCollectionEffects {

  load$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType<actions.LoadAction>(actions.LOAD),
    withLatestFrom(this.store$.pipe(select(reducers.getOperatorCollectionLookup))),
    switchMap(([action, lookup]) => {
      const request = new contacts.SearchOperatorRequest();
      request.lookup = lookup;
      return this.operatorService.searchOperator(request).pipe(
        map((serviceResponse) => {
          const loadSuccessPayload = new actions.LoadSuccessPayload(
            serviceResponse.items,
            serviceResponse.total
          );

          return new actions.LoadSuccessAction(loadSuccessPayload);
        }),
        catchError((err) => {
          this.notificationService.show(
            `Load Operators Failed`,
            sharedTypes.NotificationStyle.Bar, 
            sharedTypes.NotificationType.Danger,
            sharedTypes.NotificationPosition.TopRight,
            5000
          );
          return of(new actions.LoadFailAction());
        })
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private store$: Store<any>,
    @Inject(contacts.OPERATOR_SERVICE_TOKEN)
    private operatorService: contacts.IOperatorService,
    @Inject(sharedTypes.NOTIFICATION_SERVICE_TOKEN) 
    private notificationService: sharedTypes.INotificationService,
  ) {}
}
