import {
	AfterViewInit,
	Component,
	HostListener,
	Inject,
	OnDestroy,
	OnInit
} from '@angular/core';
import {
	ActivatedRoute,
	Event as RouterEvent,
	NavigationCancel,
	NavigationEnd,
	NavigationError,
	NavigationStart,
	Router
} from '@angular/router';
import { Store, select } from '@ngrx/store';

import * as layoutActions from '../../../shared/actions/layout';
import * as systemActions from '../../../shared/actions/system';
import * as sharedModels from '../../../shared/models';
import * as operatorActions from '../../actions/operator-collection';
import * as reducers from '../../reducers';
import * as models from '../../models';

import { merge, Observable, Subscription, of, fromEvent } from 'rxjs';
import * as userServiceDataContracts from '../../services/user/contracts/user';
import { filter, map, take, first, debounceTime } from 'rxjs/operators';
import * as userServiceContracts from '../../services/user/contracts/user';
import { environment } from '../../../../environments/environment';
import {
	ANALYTICS_SERVICE_TOKEN,
	IAnalyticsService,
	LOCAL_STORAGE_SERVICE_TOKEN,
	ILocalStorage
} from '../../../shared/services';
import { AuthService } from '@auth0/auth0-angular';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy, AfterViewInit {
	userName$: Observable<string>;
	isAppView$: Observable<boolean>;
	isFullHeightView$: Observable<boolean>;
	isFullWidthView$: Observable<boolean>;
	isLoading$: Observable<boolean>;
	isAppView: boolean;
	isFullHeightView: boolean;
	isLoading: boolean;
	user$: Observable<models.User>;
	userAccount$: Observable<models.UserAccount>;
	title$: Observable<string>;
	viewPermissions$: Observable<models.ViewPermissions>;
	menuTabIsOpen = false;
	isSidebarToggled = false;

	isDebug$: Observable<boolean>;
	version$: Observable<string>;
	environment = environment.environment;

	isNarrowDevices$: Observable<boolean>;
	operatorSelectionDialogVisible: boolean = false;

	get isIEBrowser(): boolean {
		return /msie\s|trident/i.test(window.navigator.userAgent);
	}

	private storeSubscriptions: Subscription = new Subscription();

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private store: Store<any>,
		private auth: AuthService,
		@Inject(LOCAL_STORAGE_SERVICE_TOKEN) private storageService: ILocalStorage,
		@Inject(userServiceContracts.USER_SERVICE_TOKEN)
		private userService: userServiceContracts.IUserService,
		@Inject(ANALYTICS_SERVICE_TOKEN) private analyticsService: IAnalyticsService
	) {
		this.isNarrowDevices$ = fromEvent(window, 'resize').pipe(
			map(() => window.innerWidth < 1025),
			debounceTime(100)
		);

		this.version$ = this.store.pipe(select(reducers.getSystemVersion));
		this.isDebug$ = this.store.pipe(select(reducers.getSystemIsDebug));

		this.isAppView$ = this.store.pipe(select(reducers.getLayoutIsAppView));
		this.isFullHeightView$ = this.store.pipe(
			select(reducers.getLayoutIsFullHeightView)
		);
		this.isFullWidthView$ = this.store.pipe(
			select(reducers.getLayoutIsFullWidthView)
		);
		this.isLoading$ = this.store.pipe(select(reducers.getLayoutIsLoading));
		this.user$ = this.store.pipe(select(reducers.getUserUser));
		this.userAccount$ = this.store.pipe(select(reducers.getUserAccount));
		this.viewPermissions$ = this.store.pipe(
			select(reducers.getUserUserPermissions)
		);
		this.auth.isAuthenticated$
			.pipe(
				filter(isAuthenticated => isAuthenticated),
				take(1)
			)
			.subscribe(() => {
				const redirectUrl = localStorage.getItem('randomStateValue');
				if (redirectUrl) {
					localStorage.removeItem('randomStateValue');
					this.router.navigateByUrl(redirectUrl);
				}
			});

		this.userName$ = this.store.pipe(
			select(reducers.getUserUser),
			map((user: models.User) => {
				if (user === null) {
					return '';
				}

				return `${user.firstName} ${user.lastName}`;
			})
		);

		const subscriptionRE = router.events.subscribe((event: RouterEvent) => {
			this.navigationInterceptor(event);
		});

		this.storeSubscriptions.add(subscriptionRE);
	}

	ngAfterViewInit() {
		const { events } = this.router;
		const parentRouteStream$ = events.pipe(
			filter(
				event =>
					event instanceof NavigationEnd &&
					!!this.activatedRoute.snapshot.firstChild.data
			),
			map(() => this.activatedRoute.snapshot.firstChild.data),
			map(({ title }) => title)
		);

		const childRouteStream$ = events.pipe(
			filter(
				event =>
					event instanceof NavigationEnd &&
					!!this.activatedRoute.snapshot.firstChild
			),
			map(() => this.activatedRoute.snapshot.firstChild),
			filter(({ firstChild }) => !!firstChild),
			map(({ firstChild }) => firstChild.data),
			map(({ title }) => title)
		);
		this.title$ = merge(parentRouteStream$, childRouteStream$);
	}

	onOperatorSelectionDialogShow(): void {
		this.store.dispatch(
			new operatorActions.SetLookupAction(new sharedModels.Lookup())
		);
		this.store.dispatch(new operatorActions.LoadAction());
		this.operatorSelectionDialogVisible = true;
	}

	onOperatorSelectionDialogClose(): void {
		this.operatorSelectionDialogVisible = false;
	}

	onOperatorSelect(operator: models.Operator): void {
		this.onOperatorSelectionDialogClose();
		this.storageService.setItem('selectedAngelOperatorUuid', operator.id);
		location.reload();
	}

	onSidebarToggle(event: boolean): void {
		this.isSidebarToggled = event;
	}

	closeSidebar(event: MouseEvent): void {
		const menuToggleElement: HTMLElement = document.querySelector('.btn-link');
		if (!menuToggleElement.contains(<Node>event.target)) {
			this.onSidebarToggle(false);
		}
	}

	menuTabOpen(event: boolean): void {
		this.menuTabIsOpen = event;
	}

	signOut(): void {
		this.userService
			.signOut(new userServiceDataContracts.SignOutRequest())
			.pipe(take(1))
			.subscribe(() => {
				this.analyticsService.logEvent({
					eventName: 'LogoutSuccess'
				});
				location.reload();
			});
	}

	ngOnDestroy(): void {
		this.storeSubscriptions.unsubscribe();
	}

	private navigationInterceptor(event: RouterEvent): void {
		if (event['url'] && event['url'] === '/') {
			return null;
		}

		if (event instanceof NavigationStart) {
			this.store.dispatch(new layoutActions.ShowSpinnerAction());
		}

		if (event instanceof NavigationEnd) {
			this.store.dispatch(new layoutActions.HideSpinnerAction());
		}

		if (event instanceof NavigationCancel) {
			this.store.dispatch(new layoutActions.HideSpinnerAction());
		}

		if (event instanceof NavigationError) {
			this.store.dispatch(new layoutActions.HideSpinnerAction());
		}
	}

	@HostListener('document:keypress', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent): void {
		if (event.ctrlKey && event.keyCode === 4) {
			//  Ctrl+D
			this.store
				.pipe(select(reducers.getSystemIsDebug), first())
				.subscribe(isDebug => {
					this.store.dispatch(new systemActions.SetDebugAction(!isDebug));
				});
		}
	}

	onLinkClicked(menuType: string): void {
		this.analyticsService.logEvent({
			eventName: 'CommonMainMenuItemClicked',
			properties: {
				menuType
			}
		});
	}

	onProfileClicked(): void {
		this.analyticsService.logEvent({ eventName: 'CommonUserProfileClicked' });
	}

	onAccountChange(account: models.UserAccount): void {
		this.storageService.setItem('selectedAccountId', account.operator.id);
		this.storageService.removeItem('selectedAngelOperatorUuid');
		location.reload();
	}
}
