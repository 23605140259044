<kendo-grid
  [data]="gridData"
  [skip]="state.skip"
  [pageSize]="state.take"
  [pageable]="{
    buttonCount: buttonCount,
    info: info,
    type: type,
    previousNext: previousNext
  }"
  [selectable]="{ checkboxOnly: false, mode: 'single'}"
  [kendoGridSelectBy]="selectByCallback"
  [selectedKeys]="[selectedOperator]"
  (selectedKeysChange)="onSelectionChange($event)"
  (dataStateChange)="onDataStateChangeHandler($event)"
>
  <kendo-grid-checkbox-column class="checkbox-column" width="42">
  </kendo-grid-checkbox-column>
  <kendo-grid-column
    [headerClass]="'table-header'"
    [class]="'default-cell'"
    title="Name"
  >
    <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem?.legalNameAndName }}
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
