import { ILocalStorage } from './contracts';
import { Injectable } from '@angular/core';

@Injectable()
export class MemoryStorage implements ILocalStorage {

  private data: { [key: string]: string } = {};

  getItem(key: string): string | null {
    return key in this.data ? this.data[key] : null;
  }

  setItem(key: string, value: string): void {
    this.data[key] = value;
  }

  removeItem(key: string): void {
    delete this.data[key];
  }
}

