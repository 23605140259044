import { UserAccount } from './user-account';
import { ViewPermissions } from './view-permissions';

export class User {
  firstName = '';
  lastName = '';
  viewPermissions: ViewPermissions = new ViewPermissions();
  userId: number = null;
  emailAddress = '';
  accounts: Array<UserAccount> = [];
  angelAccount: UserAccount = null;
}
