<div class="m-b-0" style="margin-left: -16px; margin-right: -16px; z-index: 2">
  <div class="container sm-p-l-20 sm-p-r-20">
    <div class="inner">
      <div class="row">
        <div class="col col-sm-12">
          <div class="modal-header-wrapper">
            <h4 class="pull-left m-l-5">Operators</h4>
            <button
              type="button"
              class="close m-r-10"
              data-dismiss="modal"
              aria-hidden="true"
              (click)="closeDialog()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30">
                  <path d="M28.79,0,14.74,13.92,1.21.52,0,1.72l13.53,13.4L.24,28.28l1.22,1.2L14.74,16.32,28.55,30l1.21-1.2L15.95,15.12,30,1.2Z" fill="#393b41" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="dialog-body-scrollable">
  <div class="container-fluid h-100 p-l-10 p-r-10 p-t-10 p-b-10">
    <div>
      <operator-selection-lookup-form
        [lookup]="lookup$ | async"
        (changed)="lookupChanged$.next($event)"
      >
      </operator-selection-lookup-form>
    </div>
    <div class="m-t-10" style="height: 80%;" *ngIf="!(loading$ | async); else loader">
      <operator-selection-list
        [gridData]="gridData$ | async"
        [lookup]="lookup$ | async"
        [selectedOperator]="selectedOperator"
        (onDataStateChange)="onDataStateChange($event)"
        (selectionChange)="onSelectionChange($event)"
      ></operator-selection-list>
    </div>
  </div>
</div>

<div class="dialog-footer-fixed">
  <div class="row">
    <div class="col col-sm-12">
      <button
        class="btn btn-primary btn-cons pull-right m-0"
        [disabled]="!selectedOperator"
        (click)="onOperatorSelectionConfirmed()"
      >Select</button>
      <button class="btn btn-default btn-cons pull-right m-r-10" (click)="closeDialog()">Close</button>
    </div>
  </div>
</div>

<ng-template #loader>
  <spinner class="full-height" [xo]="true"></spinner>
</ng-template>
