import { Injectable, Inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import * as sharedTypes from '../../shared/types';
import * as reducers from '../reducers';

@Injectable()
export class UserAgreementGuard implements CanActivate {
  constructor(
    private router: Router,
    private store$: Store<any>,
    @Inject(sharedTypes.NOTIFICATION_SERVICE_TOKEN)
    private notificationService: sharedTypes.INotificationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.store$.pipe(
      select(reducers.getUserAccount),
      switchMap((account) => {
        const superuser = account.superUser;
        const userEulaAccepted = account.eulaAccepted;
        const operatorEulaAccepted = account.operator?.eulaAccepted;
        const operatorAgreement = account.operator?.operatorAgreement;

        if (userEulaAccepted && operatorEulaAccepted && operatorAgreement) {
          return of(true);
        }

        if (!superuser && (!operatorEulaAccepted || !operatorAgreement)) {
          /** Need timeout here, otherwise notificationService fails silently, it uses setTimeout during its
           * initialization */
          const agreementName = !operatorEulaAccepted ? 'EULA' : 'CVP Agreement';
          setTimeout(() => {
            this.notificationService.show(
              `Account Owner must first accept the ${agreementName} before continuing.`,
              sharedTypes.NotificationStyle.Bar, 
              sharedTypes.NotificationType.Danger,
              sharedTypes.NotificationPosition.TopRight
            );
          }, 0);
          return of(false);
        }

        const navigateToUserAgreement = () => {
          this.router.navigate(['/user-agreement'], {
            queryParams: { 'return-url': state.url }
          });
        };

        if (superuser && !operatorEulaAccepted) {
          navigateToUserAgreement();
        } 
        else if (!userEulaAccepted) {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(navigateToUserAgreement);
        } 
        else if (superuser && !operatorAgreement) {
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(navigateToUserAgreement);
        }
        return of(false);
      })
    )
  }
  
}
