import { Injectable } from '@angular/core';
import * as contracts from './contracts/operator';

import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable()
export class OperatorMockService implements contracts.IOperatorService {

  searchOperator(
    request: contracts.SearchOperatorRequest
  ): Observable<contracts.SearchOperatorResponse> {
    return of(new contracts.SearchOperatorResponse()).pipe(delay(2000));
  }

  getOperator(request: contracts.GetOperatorRequest): Observable<contracts.GetOperatorResponse> {
    return of(new contracts.GetOperatorResponse()).pipe(delay(2000));
  }
}
