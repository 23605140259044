export class Lookup {
  skip = 0;
  limit = 15;
  total = 0;
  searchTerm: string = null;

  constructor(data?: any) {
    if (data) {
      this.searchTerm = data?.searchTerm;
    }
  }
}
