import {createSelector} from 'reselect';
import { ActionReducer, ActionReducerMap, combineReducers } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';

import * as fromLayout from '../../shared/reducers/layout';
import * as fromSystem from '../../shared/reducers/system';
import * as fromAirport from '../../shared/reducers/airport';
import * as fromCountryCollection from '../../shared/reducers/country-collection-list'
import * as fromOperatorCollection from './operator-collection';
import * as fromUser from './user';
import * as fromApiStatus from './api-status';

export interface State {
  layout: fromLayout.State;
  router: fromRouter.RouterReducerState;
  system: fromSystem.State;
  user: fromUser.State;
  airport: fromAirport.State;
  countryCollection: fromCountryCollection.State;
  apiStatus: fromApiStatus.State;
  operatorCollection: fromOperatorCollection.State;

}

export const reducers: ActionReducerMap<State> = {
  layout: fromLayout.reducer,
  router: fromRouter.routerReducer,
  system: fromSystem.reducer,
  user: fromUser.reducer,
  airport: fromAirport.reducer,
  countryCollection: fromCountryCollection.reducer,
  apiStatus: fromApiStatus.reducer,
  operatorCollection: fromOperatorCollection.reducer,
};

const productionReducer: ActionReducer<State> = combineReducers(reducers);

export function reducer(state: any, action: any): State {
  return productionReducer(state, action);
}

/**
 * Layout Reducers
 */
export const getLayoutState = (state: any) => state.core.layout;
export const getLayoutIsAppView = createSelector(getLayoutState, fromLayout.getIsAppView);
export const getLayoutIsFullHeightView = createSelector(getLayoutState, fromLayout.getIsFullHeightView);
export const getLayoutIsFullWidthView = createSelector(getLayoutState, fromLayout.getIsFullWidthView);
export const getLayoutIsLoading = createSelector(getLayoutState, fromLayout.getIsLoading);
export const getLayoutType = createSelector(getLayoutState, fromLayout.getLayoutType);

/**
 * System Reducers
 */
export const getSystemState = (state: any) => state.core.system;
export const getBrowserSupported = createSelector(getSystemState, fromSystem.getBrowserSupported);
export const getSystemIsDebug = createSelector(getSystemState, fromSystem.getIsDebug);
export const getSystemVersion = createSelector(getSystemState, fromSystem.getVersion);

/**
 * User Reducers
 */
export const getUserState = (state: any) => state.core.user;
export const getUserUser = createSelector(getUserState, fromUser.getUser);
export const getUserAccount = createSelector(getUserState, fromUser.getUserAccount);
export const getUserOrgLegalName = createSelector(getUserState, fromUser.getUserOrgLegalName);
export const getUserUserPermissions = createSelector(getUserState, fromUser.getUserPermissions);
export const getUserUserPermissionsFulfillFlights = createSelector(getUserState, fromUser.getUserPermissionsFulfillFlights);
export const getUserUserPermissionsPublishedFlights = createSelector(getUserState, fromUser.getUserPermissionsPublishedFlights);

/**
 * Route Reducers
 */
export const getRouterState = (state: any) => state.core.router;
export const getRouterPath = createSelector(getRouterState, (state: fromRouter.RouterReducerState) => state.state && state.state.url);

/**
 * Airport Reducers
 */
export const getAirportState = (state: any) => state.core.airport;
export const getAirportOptions = createSelector(getAirportState, fromAirport.getAirportOptions);
export const getAirportOptionsLoading = createSelector(getAirportState, fromAirport.getAirportOptionsLoading);

/**
 * Country Collection Reducers
 */
export const getCountryCollectionState = (state: any) => state.core.countryCollection;
export const getCountryCollectionItems = createSelector(getCountryCollectionState, fromCountryCollection.getCountriesCollection);
export const getCountryCollectionItemsLoading = createSelector(getCountryCollectionState, fromCountryCollection.getIsLoading);
export const getCountryCollectionItemsLoaded = createSelector(getCountryCollectionState, fromCountryCollection.getIsLoaded);

/**
 * Api Status Reducers
 */
export const getApiStatusState = (state: any) => state.core.apiStatus;
export const getApiStatus = createSelector(getApiStatusState, fromApiStatus.getApiStatus);
export const getApiStatusIsLoaded = createSelector(getApiStatusState, fromApiStatus.getApiStatusIsLoaded);
export const getApiStatusIsLoading = createSelector(getApiStatusState, fromApiStatus.getApiStatusIsLoading);

/**
 * Operator Collection Reducers
 */
export const getOperatorCollectionState = (state: any) => state.core.operatorCollection;
export const getOperatorCollectionItems = createSelector(getOperatorCollectionState, fromOperatorCollection.getItems);
export const getOperatorCollectionItemsLoading = createSelector(getOperatorCollectionState, fromOperatorCollection.getIsLoading);
export const getOperatorCollectionItemsLoaded = createSelector(getOperatorCollectionState, fromOperatorCollection.getIsLoaded);
export const getOperatorCollectionLookup = createSelector(getOperatorCollectionState, fromOperatorCollection.getLookup);