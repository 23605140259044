import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges
} from '@angular/core';
import { Operator } from '../../models/operator';
import { Lookup } from '../../../shared/models';
import { State } from '@progress/kendo-data-query';
import { DataStateChangeEvent, GridDataResult, RowArgs } from '@progress/kendo-angular-grid';
import * as _ from 'lodash';

@Component({
	selector: 'operator-selection-list',
  styleUrls: ['./operator-selection-list.component.scss'],
	templateUrl: './operator-selection-list.component.html'
})
export class OperatorSelectionListComponent implements OnChanges {
	@Input() gridData: GridDataResult;
	@Input() selectedOperator: Operator;
	@Input() lookup: Lookup;
	@Output() selectionChange = new EventEmitter<Operator>();
  @Output() onDataStateChange =  new EventEmitter<Lookup>();

	selectByCallback = (context: RowArgs) => context.dataItem;

	state: State = {
		skip: 0,
		take: 15
	};
  buttonCount = 5;
  info = true;
  type = 'numeric';
  pageSizes = true;
  previousNext = true;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.lookup?.currentValue) {
			const state: State = _.cloneDeep(this.state);
			state.skip = this.lookup.skip;
			state.take = this.lookup.limit;
			this.state = state;
		}
	}

	onSelectionChange(operators: Array<Operator>): void {
		const [selectedOperator] = operators;
		this.selectionChange.emit(selectedOperator);
	}

  onDataStateChangeHandler(state: DataStateChangeEvent): void {
    const lookup: Lookup = _.cloneDeep(this.lookup);
    lookup.skip = state.skip;
    lookup.limit = state.take;
    this.onDataStateChange.emit(lookup);
  }
}
