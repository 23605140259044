export class OperatorContact {
	private firstName: string = null;
	private lastName: string = null;
	private title: string = null;
	private phoneNumber: string = null;
	private email: string = null;
	private quoteRequestEmail: string = null;
	private instantBookingEmail: string = null;

	getFirstName(): string {
		return this.firstName;
	}
	setFirstName(value: string): OperatorContact {
		this.firstName = value;
		return this;
	}
	getLastName(): string {
		return this.lastName;
	}
	setLastName(value: string): OperatorContact {
		this.lastName = value;
		return this;
	}
	getTitle(): string {
		return this.title;
	}
	setTitle(value: string): OperatorContact {
		this.title = value;
		return this;
	}
	getPhoneNumber(): string {
		return this.phoneNumber;
	}
	setPhoneNumber(value: string): OperatorContact {
		this.phoneNumber = value;
		return this;
	}
	getEmail(): string {
		return this.email;
	}
	setEmail(value: string): OperatorContact {
		this.email = value;
		return this;
	}
  getQuoteRequestEmail(): string {
    return this.quoteRequestEmail;
  };
  setQuoteRequestEmail(value: string): OperatorContact {
    this.quoteRequestEmail = value;
    return this;
  };
	getInstantBookingEmail(): string {
    return this.instantBookingEmail;
  }
	setInstantBookingEmail(value: string): OperatorContact {
    this.instantBookingEmail = value;
    return this;
  };

}
