import {
	Component,
	Output,
	EventEmitter,
	HostListener,
	OnDestroy
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as reducers from '../../reducers';
import * as actions from '../../actions/operator-collection';
import {
	BehaviorSubject,
	combineLatest,
	Observable,
	Subject,
	Subscription
} from 'rxjs';
import { Operator } from '../../models';
import { Lookup } from '../../../shared/models';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
	selector: 'operator-selection-dialog',
	templateUrl: './operator-selection-dialog.component.html',
	styleUrls: ['./operator-selection-dialog.component.scss']
})
export class OperatorSelectionDialogComponent implements OnDestroy {
	gridData$: BehaviorSubject<GridDataResult>;
	operators$: Observable<Array<Operator>>;
	loading$: Observable<boolean>;
	lookup$: Observable<Lookup>;
	operatorCollectionSubscription$: Subscription;
	@Output() operatorSelected = new EventEmitter<Operator>();
	@Output() close = new EventEmitter<void>();
	selectedOperator: Operator = null;
	lookupChanged$: Subject<Lookup> = new Subject<Lookup>();
	private destroy$: Subject<void> = new Subject<void>();

	@HostListener('document:keydown.escape', ['$event'])
	handleKeyboardEvent(event: KeyboardEvent): void {
		this.closeDialog();
	}

	constructor(private store$: Store<any>) {
		this.operators$ = this.store$.pipe(
			select(reducers.getOperatorCollectionItems)
		);
		this.loading$ = this.store$.pipe(
			select(reducers.getOperatorCollectionItemsLoading)
		);
		this.lookup$ = this.store$.pipe(
			select(reducers.getOperatorCollectionLookup)
		).pipe(tap((a) => console.log(a)));

		this.operatorCollectionSubscription$ = combineLatest([
			this.operators$,
			this.lookup$
		])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([collection, lookup]) => {
				this.updateGrid(collection, lookup);
			});

		this.lookupChanged$
			.pipe(
				debounceTime(1000),
				distinctUntilChanged(),
				takeUntil(this.destroy$)
			)
			.subscribe(lookup => {
				const lookupClone = _.cloneDeep(lookup);
				lookupClone.skip = 0;
				this.onDataStateChange(lookupClone);
			});
	}

	closeDialog(): void {
		this.close.emit();
	}

	onSelectionChange(operator: Operator) {
		this.selectedOperator = operator;
	}

	onOperatorSelectionConfirmed(): void {
		if (this.selectedOperator) {
			this.operatorSelected.emit(this.selectedOperator);
		}
	}

	onDataStateChange(lookup: Lookup): void {
		this.store$.dispatch(new actions.SetLookupAction(lookup));
		this.store$.dispatch(new actions.LoadAction());
		this.selectedOperator = null;
	}

	updateGrid(operators: Array<Operator>, criteria: Lookup): void {
		if (this.gridData$ === undefined) {
			this.gridData$ = new BehaviorSubject<GridDataResult>({
				data: operators,
				total: criteria.total
			});
		} else {
			this.gridData$.next({
				data: operators,
				total: criteria.total
			});
		}
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}
}
