import { InjectionToken } from '@angular/core';
import * as models from '../../../models';
import * as sharedModels from '../../../../shared/models';
import { Observable } from 'rxjs';

export interface IOperatorService {
  searchOperator(request: SearchOperatorRequest): Observable<SearchOperatorResponse>;
  getOperator(request: GetOperatorRequest): Observable<GetOperatorResponse>;
}

export const OPERATOR_SERVICE_TOKEN = new InjectionToken('Core.IOperatorService');

export class SearchOperatorRequest {
  lookup: sharedModels.Lookup;
}

export class SearchOperatorResponse {
  items: Array<models.Operator>;
  total: number;
}

export class GetOperatorRequest {
  operatorUuid: string;
}

export class GetOperatorResponse {
  item: models.Operator;
}
