import { Injectable } from '@angular/core';
import { OperatorMapperService } from './mappers/operator-mapper.service';
import * as contracts from './contracts/operator';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Apollo, gql } from 'apollo-angular';
import { PaginationInput } from '../../../shared/graphql-types';

@Injectable()
export class OperatorService implements contracts.IOperatorService {
	constructor(public apollo: Apollo) {}

	searchOperator(
		request: contracts.SearchOperatorRequest
	): Observable<contracts.SearchOperatorResponse> {
		const { lookup } = request;

		const pagination: PaginationInput = {
			skip: lookup?.skip,
			limit: lookup?.limit
		};

		const filter: any = {
			and: [{ active: { eq: true } }]
		};

		if (lookup?.searchTerm) {
			filter.and.push({
				or: [
					{ name: { contains: lookup.searchTerm } },
					{ legalName: { contains: lookup.searchTerm } }
				]
			});
		}

		const sorting = {
			legalName: true,
			name: true
		};

		return this.apollo
			.query<any>({
				query:
					gql(`query listOperators($filter: OperatorFilterInput, $sorting: OperatorSortingInput, $pagination: PaginationInput ) {
        listOperators(filter: $filter, sorting: $sorting, pagination: $pagination) {
          operators {
            operatorUuid
            name
            legalName
            operatorAgreement
            prospectus {
              approved
            }
          }
          pagination {
            count
          }
        }
      }`),
				variables: {
					filter,
					sorting,
					pagination
				}
			})
			.pipe(
				map((svcJsonResp: any) => {
					const response = new contracts.SearchOperatorResponse();

					if (!svcJsonResp?.data) {
						return response;
					}

					response.items = svcJsonResp.data.listOperators.operators.map(item =>
						OperatorMapperService.parseOperatorInternal(item)
					);
					response.total = svcJsonResp.data.listOperators?.pagination?.count;
					return response;
				})
			);
	}

	getOperator(
		request: contracts.GetOperatorRequest
	): Observable<contracts.GetOperatorResponse> {
		return this.apollo
			.query<any>({
				query: gql(`query getOperator($operatorUuid: String) {
        getOperator(operatorUuid: $operatorUuid) {
          operatorUuid
          operatorAgreement
          legalName
          name
          prospectus {
            approved
          }
        }
      }`),
				variables: {
					operatorUuid: request.operatorUuid
				}
			})
			.pipe(
				map((svcJsonResp: any) => {
					const response = new contracts.GetOperatorResponse();

					if (!svcJsonResp?.data) {
						return response;
					}

					response.item = OperatorMapperService.parseOperatorInternal(
						svcJsonResp.data.getOperator
					);
					return response;
				})
			);
	}
}
