import { Injectable } from '@angular/core';
import { ILocalStorage } from './contracts';
import { MemoryStorage } from './memory-storage.service';

@Injectable()
export class BaseLocalStorageService implements ILocalStorage {

  private readonly storage: ILocalStorage;

  constructor(
    private memoryStorage: MemoryStorage,
  ) {
    if (this.isLocalStorageAvailable()) {
      this.storage = window.localStorage;
    } else {
      this.storage = this.memoryStorage;
    }
  }

  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  setItem(key: string, value: string): void {
    this.storage.setItem(key, value);
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  isLocalStorageAvailable(): boolean {
    try {
      const storage = window.localStorage;
      const x = 'test';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return false;
    }
  }
}
